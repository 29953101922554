import { dev } from '$app/environment';
import { PUBLIC_CLERK_PUBLISHABLE_KEY } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { initClerkClient } from '@tickrr/ui/auth';

initClerkClient(PUBLIC_CLERK_PUBLISHABLE_KEY);

/**
 * For Replay configuration options, see: https://docs.sentry.io/platforms/javascript/guides/sveltekit/session-replay/configuration/?original_referrer=https://www.google.com/
 */
Sentry.init({
	dsn: 'https://fa6a15b498d3457faa794b0cf584429c@o4505315796254720.ingest.sentry.io/4505315800121344',
	enabled: dev ? false : true,
	integrations: [Sentry.replayIntegration({ blockAllMedia: false, maskAllText: false })],
	replaysOnErrorSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	tracesSampleRate: 1
});

// If/when we need to add a custom error handler, pass it as an arrow function here.
export const handleError = Sentry.handleErrorWithSentry();
