import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/(non-app)/(landing-pages)": [49,[16]],
		"/_test": [~78],
		"/admin/dl-company-logos": [~79],
		"/(app)/alerts": [~25,[2],[3]],
		"/(auth)/auth-callback": [43,[14]],
		"/(auth)/auth-verify-session-token": [44,[15]],
		"/(redirects)/auth": [71],
		"/(app)/backoffice": [~26,[2,6],[3]],
		"/(app)/backoffice/emails": [27,[2,6],[3]],
		"/(app)/backoffice/manage/articles": [~28,[2,6],[3]],
		"/(app)/backoffice/manage/changelog": [~29,[2,6],[3]],
		"/(app)/backoffice/manage/publishers": [~30,[2,6],[3]],
		"/(app)/backoffice/manage/reports": [~31,[2,6],[3]],
		"/(non-app)/blog": [58,[18]],
		"/(non-app)/blog/(articles)/5-common-investing-mistakes": [59,[18,19]],
		"/(non-app)/blog/(articles)/crypto-trading-101": [60,[18,19]],
		"/(non-app)/blog/(articles)/from-novice-to-pro-investment-foundation": [61,[18,19]],
		"/(non-app)/blog/(articles)/fundamental-v-technical-analysis-for-blue-chip-stocks": [62,[18,19]],
		"/(non-app)/blog/(articles)/fundamental-v-technical-analysis-for-meme-stocks": [63,[18,19]],
		"/(non-app)/blog/(articles)/impact-of-breaking-news-on-stock-prices": [64,[18,19]],
		"/(non-app)/blog/(articles)/real-time-market-data-importance": [65,[18,19]],
		"/(non-app)/blog/(articles)/social-investing": [66,[18,19]],
		"/(non-app)/blog/(articles)/top-10-meme-stock-indicators": [67,[18,19]],
		"/(non-app)/blog/(articles)/v2": [68,[18,19]],
		"/(app)/(feeds)/(meta)/bookmarks": [20,[2,4],[3]],
		"/(non-app)/changelog": [~69,[16]],
		"/(app)/charts/[[symbol]]": [~32,[2,7],[3]],
		"/(non-app)/(subscriptions)/checkout-cancel": [54,[16]],
		"/(non-app)/(subscriptions)/checkout-complete": [~55,[16]],
		"/(non-app)/(subscriptions)/checkout-success": [56,[16]],
		"/(non-app)/(subscriptions)/checkout": [~53,[16]],
		"/(non-app)/(legal)/cookies": [50,[16,17]],
		"/(auth)/extension-callback": [45,[13]],
		"/(redirects)/extension": [~72],
		"/(redirects)/extension/install": [~73],
		"/(app)/home": [33,[2],[3]],
		"/(redirects)/install": [~74],
		"/(redirects)/item/[itemType]/[id]": [~75],
		"/(app)/i/[id]": [~34,[2,8],[3]],
		"/(redirects)/launch": [~76],
		"/(app)/markets": [35,[2,9],[3]],
		"/(app)/(feeds)/(std)/news": [23,[2,5],[3]],
		"/(app)/new": [36,[2,10],[3]],
		"/(app)/notifications": [~37,[2],[3]],
		"/(redirects)/onboarding": [~77],
		"/(app)/(feeds)/(std)/posts": [24,[2,5],[3]],
		"/(non-app)/(legal)/privacy": [51,[16,17]],
		"/(app)/profile": [~38,[2],[3]],
		"/(app)/profile/[username]": [~39,[2],[3]],
		"/(app)/(feeds)/(meta)/publisher/[publisherUsername]": [21,[2],[3]],
		"/(app)/screener": [40,[2],[3]],
		"/(app)/search/[[searchQuery]]": [41,[2,11],[3]],
		"/(auth)/signin": [46,[13]],
		"/(auth)/signout": [47,[13]],
		"/(auth)/signup": [48,[13]],
		"/(non-app)/(subscriptions)/subscribe": [~57,[16]],
		"/(non-app)/symbol/[symbol]": [~70,[16]],
		"/(app)/(feeds)/(meta)/tag/[tagValue]": [~22,[2],[3]],
		"/(non-app)/(legal)/tos": [52,[16,17]],
		"/(app)/watchlist": [42,[2,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';